<template>
  <v-container>
    <v-card elevation="2">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Point categories</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <figure class="barGraph">
          <v-chart :options="chartBarScaffold" autoresize />
        </figure>
        <v-row
          ><v-spacer></v-spacer>
          <v-col cols="6">
            <v-btn
              text
              small
              dense
              color="red darken-3"
              @click="calculateNormalDistribution()"
              >Reset to normal distribution</v-btn
            >
            <v-btn
              small
              dense
              text
              color="red darken-3"
              @click="calculateStandardDeviation()"
              >Reset to standard deviation</v-btn
            > </v-col
          ><v-spacer></v-spacer>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="2" class="mt-12">
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>adjustments</th>
                <th>low point value</th>
                <th>high point value</th>
                <th>bonus count</th>
                <th>percentage of total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Small</td>
                <td>
                  <v-icon color="blue darken-3" small>{{
                    MATERIALICONSYMBOLS[
                      Preferences.symbol.tshirt['small'].symbol
                    ]
                  }}</v-icon>
                </td>
                <td>
                  <v-btn icon @click="adjustCategories('decreaseLow')"
                    ><v-icon small>mdi-minus-circle</v-icon>
                  </v-btn>
                  <v-btn icon @click="adjustCategories('increaseLow')"
                    ><v-icon small>mdi-plus-circle</v-icon>
                  </v-btn>
                </td>
                <td>0</td>
                <td>
                  {{ saveRallies.tshirt.small.maximum }}
                </td>
                <td class="font-weight-black">
                  {{ saveRallies.tshirt.small.count }}
                </td>
                <td>{{ saveRallies.tshirt.small.percentage }}%</td>
              </tr>
              <tr>
                <td>Medium</td>
                <td>
                  <v-icon color="blue darken-3" small>{{
                    MATERIALICONSYMBOLS[
                      Preferences.symbol.tshirt['medium'].symbol
                    ]
                  }}</v-icon>
                </td>
                <td>
                  <v-btn icon @click="adjustCategories('decreaseMedium')"
                    ><v-icon small>mdi-minus-circle</v-icon>
                  </v-btn>
                  <v-btn icon @click="adjustCategories('increaseMedium')"
                    ><v-icon small>mdi-plus-circle</v-icon>
                  </v-btn>
                </td>
                <td>{{ saveRallies.tshirt.small.maximum + 1 }}</td>
                <td>
                  {{ saveRallies.tshirt.medium.maximum }}
                </td>
                <td class="font-weight-black">
                  {{ saveRallies.tshirt.medium.count }}
                </td>
                <td>{{ saveRallies.tshirt.medium.percentage }}%</td>
              </tr>
              <tr>
                <td>Large</td>
                <td>
                  <v-icon color="blue darken-3" small>{{
                    MATERIALICONSYMBOLS[
                      Preferences.symbol.tshirt['large'].symbol
                    ]
                  }}</v-icon>
                </td>
                <td>
                  <v-btn icon @click="adjustCategories('decreaseHigh')"
                    ><v-icon small>mdi-minus-circle</v-icon>
                  </v-btn>
                  <v-btn icon @click="adjustCategories('increaseHigh')"
                    ><v-icon small>mdi-plus-circle</v-icon>
                  </v-btn>
                </td>
                <td>{{ saveRallies.tshirt.medium.maximum + 1 }}</td>
                <td>
                  {{ saveRallies.tshirt.large.maximum }}
                </td>
                <td class="font-weight-black">
                  {{ saveRallies.tshirt.large.count }}
                </td>
                <td>{{ saveRallies.tshirt.large.percentage }}%</td>
              </tr>
              <tr>
                <td>Extralarge</td>
                <td>
                  <v-icon color="blue darken-3" small>{{
                    MATERIALICONSYMBOLS[
                      Preferences.symbol.tshirt['extralarge'].symbol
                    ]
                  }}</v-icon>
                </td>
                <td></td>
                <td>{{ saveRallies.tshirt.large.maximum + 1 }}</td>
                <td>
                  {{ saveRallies.tshirt.extralarge.maximum }}
                </td>
                <td class="font-weight-black">
                  {{ saveRallies.tshirt.extralarge.count }}
                </td>
                <td>{{ saveRallies.tshirt.extralarge.percentage }}%</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-btn
              elevation="0"
              class="mt-5"
              :loading="loadingSaveButton"
              color="green white--text"
              @click="saveChanges()"
              >Save changes</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { RalliesPrototype } from '@/components/prototypes/rallies.js'
import * as Constants from '@/components/prototypes/constants.js'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/dataset'
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/markPoint'
import 'echarts/lib/component/markArea'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/dataZoom'

export default {
  name: 'PointCategories',
  components: {
    'v-chart': ECharts
  },
  data() {
    const data = {
      saveRallies: new RalliesPrototype(),
      loadingSaveButton: false,
      chartBarScaffold: {
        title: {
          text: 'Bonus point values - analysis',
          subtext: 'Sorted by value',
          textAlign: 'auto'
        },
        dataset: {
          dimensions: ['name', 'value'],
          source: []
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          }
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            magicType: {
              show: true,
              type: ['line', 'bar'],
              title: { line: 'Line graph', bar: 'Bar graph' }
            },
            restore: { show: true, title: 'Restore view' },
            saveAsImage: { show: true, title: 'Download image' }
          }
        },
        calculable: true,
        xAxis: { type: 'category' },
        yAxis: { type: 'value' },
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100
          },
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            show: true,
            yAxisIndex: 0,
            filterMode: 'empty',
            width: 30,
            height: '80%',
            showDataShadow: false,
            left: '93%'
          }
        ],
        series: [
          {
            name: 'points',
            type: 'bar',
            markPoint: {
              data: [
                { type: 'max', name: 'maximum' },
                { type: 'min', name: 'minimum' }
              ]
            },
            markArea: {
              data: []
            },
            markLine: {
              data: []
            }
          }
        ]
      }
    }
    return { ...data, ...Constants }
  },
  computed: {
    ...mapState({
      UserProfile: (state) => state.moduleUser.UserProfile,
      Preferences: (state) => state.modulePreferences.Preferences
    }),
    ...mapGetters('moduleBonusLocations', ['BonusLocationsRidingOnlyGetter']),
    ...mapGetters('moduleRallies', ['RalliesGetRallyByIdGetter'])
  },
  created: function () {
    this.saveRallies = {
      ...this.RalliesGetRallyByIdGetter(this.UserProfile.activerallyid)
    }
    this.saveRallies.__proto = RalliesPrototype.prototype
    this.initializeBarChart()
    this.loadTableData()
    if (
      this.saveRallies.tshirt.extralarge.count === 0 ||
      this.saveRallies.tshirt.extralarge.count === null
    ) {
      this.calculateStandardDeviation()
    } else {
      this.updateMarkLineArea()
    }
  },
  methods: {
    ...mapActions('moduleRallies', ['RalliesCreateUpdateAction']),
    ...mapActions('moduleBonusLocations', ['BonusLocationsCreateUpdateAction']),
    async updateBonusLocationSymbolValues() {
      for (let index in this.BonusLocationsRidingOnlyGetter) {
        this.BonusLocationsRidingOnlyGetter[index].setValue()
        await this.BonusLocationsCreateUpdateAction(
          this.BonusLocationsRidingOnlyGetter[index]
        )
      }
    },
    async saveChanges() {
      this.loadingSaveButton = true
      await this.RalliesCreateUpdateAction(this.saveRallies)
      await this.updateBonusLocationSymbolValues()
      this.loadingSaveButton = false
    },
    adjustCategories(_button) {
      let maximumPoints =
        this.chartBarScaffold.series[0].markArea.data[3][1].yAxis
      let step = Math.round(
        maximumPoints / this.BonusLocationsRidingOnlyGetter.length
      )
      this.saveRallies.__proto__ = RalliesPrototype.prototype
      switch (_button) {
        case 'increaseHigh':
          this.saveRallies.setTshirt(
            this.saveRallies.tshirt.small.maximum,
            this.saveRallies.tshirt.medium.maximum,
            this.saveRallies.tshirt.large.maximum + step,
            this.saveRallies.tshirt.extralarge.maximum
          )
          break
        case 'decreaseHigh':
          this.saveRallies.setTshirt(
            this.saveRallies.tshirt.small.maximum,
            this.saveRallies.tshirt.medium.maximum,
            this.saveRallies.tshirt.large.maximum - step,
            this.saveRallies.tshirt.extralarge.maximum
          )
          break
        case 'increaseMedium':
          this.saveRallies.setTshirt(
            this.saveRallies.tshirt.small.maximum,
            this.saveRallies.tshirt.medium.maximum + step,
            this.saveRallies.tshirt.large.maximum,
            this.saveRallies.tshirt.extralarge.maximum
          )
          break
        case 'decreaseMedium':
          this.saveRallies.setTshirt(
            this.saveRallies.tshirt.small.maximum,
            this.saveRallies.tshirt.medium.maximum - step,
            this.saveRallies.tshirt.large.maximum,
            this.saveRallies.tshirt.extralarge.maximum
          )
          break
        case 'increaseLow':
          this.saveRallies.setTshirt(
            this.saveRallies.tshirt.small.maximum + step,
            this.saveRallies.tshirt.medium.maximum,
            this.saveRallies.tshirt.large.maximum,
            this.saveRallies.tshirt.extralarge.maximum
          )
          break
        case 'decreaseLow':
          this.saveRallies.setTshirt(
            this.saveRallies.tshirt.small.maximum - step,
            this.saveRallies.tshirt.medium.maximum,
            this.saveRallies.tshirt.large.maximum,
            this.saveRallies.tshirt.extralarge.maximum
          )
          break
      }
      this.updateMarkLineArea()
    },
    /**
     * Get all non-mappable bonuses, sort them in numerical order (small to large) and add them to the graph data array for display
     */
    loadTableData() {
      let location = {}
      let sortedBonusLocations = JSON.parse(
        JSON.stringify(this.BonusLocationsRidingOnlyGetter)
      ).sort((a, b) => (Number(a.points) > Number(b.points) ? 1 : -1))

      for (let index in sortedBonusLocations) {
        location = {
          name: sortedBonusLocations[index].name,
          value: Number(sortedBonusLocations[index].points)
        }
        this.chartBarScaffold.dataset.source.push(location)
      }
    },
    calculateStandardDeviation() {
      const math = require('mathjs')
      let values = []
      let sortedBonusLocations = JSON.parse(
        JSON.stringify(this.BonusLocationsRidingOnlyGetter)
      ).sort((a, b) => (Number(a.points) > Number(b.points) ? 1 : -1))
      for (let index in sortedBonusLocations) {
        values.push(Number(sortedBonusLocations[index].points))
      }
      let small = Math.round(math.mean(values))
      let medium = Math.round(math.mean(values) + math.std(values))
      let large = Math.round(math.mean(values) + 2 * math.std(values))
      let extralarge = values[values.length - 1]
      this.saveRallies.__proto__ = RalliesPrototype.prototype
      this.saveRallies.setTshirt(small, medium, large, extralarge)
      this.updateMarkLineArea()
    },
    calculateNormalDistribution() {
      let values = []
      let sortedBonusLocations = JSON.parse(
        JSON.stringify(this.BonusLocationsRidingOnlyGetter)
      )
      sortedBonusLocations.sort((a, b) =>
        Number(a.points) > Number(b.points) ? 1 : -1
      )
      for (let index in sortedBonusLocations) {
        values.push(Number(sortedBonusLocations[index].points))
      }
      let small = values[Math.round(values.length * 0.16)]
      let medium = values[Math.round(values.length * 0.5)]
      let large = values[Math.round(values.length * 0.84)]
      let extralarge = values[values.length - 1]
      this.saveRallies.setTshirt(small, medium, large, extralarge)
      this.updateMarkLineArea()
    },
    updateMarkLineArea() {
      this.chartBarScaffold.series[0].markArea.data[3][1].yAxis =
        this.saveRallies.tshirt.extralarge.maximum
      this.chartBarScaffold.series[0].markArea.data[3][0].yAxis =
        this.saveRallies.tshirt.large.maximum
      this.chartBarScaffold.series[0].markArea.data[2][1].yAxis =
        this.saveRallies.tshirt.large.maximum
      this.chartBarScaffold.series[0].markLine.data[2].yAxis =
        this.saveRallies.tshirt.large.maximum + 1
      this.chartBarScaffold.series[0].markArea.data[1][0].yAxis =
        this.saveRallies.tshirt.medium.maximum
      this.chartBarScaffold.series[0].markArea.data[2][0].yAxis =
        this.saveRallies.tshirt.medium.maximum
      this.chartBarScaffold.series[0].markLine.data[1].yAxis =
        this.saveRallies.tshirt.medium.maximum + 1
      this.chartBarScaffold.series[0].markArea.data[0][1].yAxis =
        this.saveRallies.tshirt.small.maximum
      this.chartBarScaffold.series[0].markArea.data[1][1].yAxis =
        this.saveRallies.tshirt.small.maximum
      this.chartBarScaffold.series[0].markLine.data[0].yAxis =
        this.saveRallies.tshirt.small.maximum + 1
    },
    initializeBarChart() {
      // draw 3 demarcation lines to illustrate the small, medium, large and extralarge areas
      this.chartBarScaffold.series[0].markLine.data.push({
        name: 'Median',
        yAxis: this.saveRallies.tshirt.small.maximum,
        lineStyle: {
          width: 2
        }
      })
      this.chartBarScaffold.series[0].markLine.data.push({
        name: 'Median + 1 std',
        yAxis: this.saveRallies.tshirt.medium.maximum
      })
      this.chartBarScaffold.series[0].markLine.data.push({
        name: 'Median + 2 std',
        yAxis: this.saveRallies.tshirt.large.maximum
      })
      // apply different background colors for small, medium, large and extralarge areas
      this.chartBarScaffold.series[0].markArea.data.push([
        {
          yAxis: 'min',
          itemStyle: { color: '#E3F2FD' }
        },
        { yAxis: this.saveRallies.tshirt.small.maximum }
      ])
      this.chartBarScaffold.series[0].markArea.data.push([
        {
          yAxis: this.saveRallies.tshirt.medium.maximum,
          itemStyle: { color: '#BBDEFB' }
        },
        { yAxis: this.saveRallies.tshirt.small.maximum }
      ])
      this.chartBarScaffold.series[0].markArea.data.push([
        {
          yAxis: this.saveRallies.tshirt.medium.maximum,
          itemStyle: { color: '#90CAF9' }
        },
        { yAxis: this.saveRallies.tshirt.large.maximum }
      ])
      this.chartBarScaffold.series[0].markArea.data.push([
        {
          yAxis: this.saveRallies.tshirt.large.maximum,
          itemStyle: { color: '#64B5F6' }
        },
        { yAxis: this.saveRallies.tshirt.extralarge.maximum }
      ])
    }
  }
}
</script>

<style>
figure.barGraph {
  width: 100%;
  height: 400px;
}
.echarts {
  width: 100%;
  height: 100%;
}
</style>
